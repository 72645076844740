<div class="pd10">  
    <div class="header">
        <div class="col-md-6" style="padding-left: 30px;">
            <div class="logo-width">
                <img src="../../assets/images/logo_update.png" />
            </div>        
        </div>
        <div class="col-md-6 login-acc voilet-text" [hidden]=hideElement>
            <span style="font-size: 15px; margin-right: 10px;" class="fw-500" [hidden]="hideOnboard">{{username}}</span>
            <div  [hidden]="hideInvestorButton">
                <button mat-raised-button color="primary" class="caps" (click)="addInvestor()">Add Investor</button>
            </div> 
            <div  [hidden]="hideInvestNowButton">
                <button mat-raised-button color="primary" class="caps" (click)="investNow()">Invest Now</button>
            </div>           
            <span class="ml-10">
                <mat-icon (click)="logout()">power_settings_new</mat-icon>
            </span>
        </div>
    </div>
</div>  